import ALL_PERMITS from '@/graphql/permits/query/getAllPermitsAutocomplete.gql'

const permits = ref<{label: string; value: string}[]>([])
const key = ref(0)
const firstTime = ref(true)

export async function permitsAutocomplete(params: any) {
  const apolloQuery = ALL_PERMITS
  const variables = {
    search: '%' + params.search + '%',
    orderBy: [
      {
        column: getAuthUser()?.value?.language?.toLowerCase() === 'en'
          ? 'NAME_EN'
          : getAuthUser()?.value?.language?.toLowerCase() === 'es'
            ? 'NAME_ES'
            : getAuthUser()?.value?.language?.toLowerCase() === 'fr'
              ? 'NAME_FR'
              : getAuthUser()?.value?.language?.toLowerCase() === 'de'
                ? 'NAME_DE'
                : getAuthUser()?.value?.language?.toLowerCase() === 'sv'
                  ? 'NAME_SV'
                  : 'NAME_AR',
        order: params.sortDesc ? 'DESC' : 'ASC'
      }
    ],
    first: 10,
    page: 1
  }

  const result = query(apolloQuery, variables, { fetchPolicy: 'network-only' })

  return result
}

export async function searchPermitsCb(queryString: any, cb: (arg0: any) => void) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await permitsAutocomplete({ search: queryString })
    cb(results?.getAllPermits?.data ?? [])
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchPermitsCb')
  }
}

export function getSearchPermits(entityPermit: any, entityPermits: any) {
  if (firstTime.value === true) {
    firstTime.value = false
    searchPermits('', entityPermit, entityPermits)
  }

  return permits?.value ?? []
}

export async function searchPermits(queryString: any, entityPermit: any, entityPermits: any[], refresh = false) {
  const { $showError, $sentry } = useNuxtApp()
  try {
    const results = await permitsAutocomplete({ search: queryString })
    const remotePermits = results?.getAllPermits?.data?.map((i: any) => {
      return {
        label: i.name,
        value: i.uuid
      }
    }) ?? []
    const permitsToAdd: { label: any; value: any }[] = []
    entityPermits?.forEach((j: any) => {
      if (!remotePermits.find((k: any) => k.value === j.uuid)) {
        permitsToAdd.push({
          label: j.name,
          value: j.uuid
        })
      }
    })
    if (entityPermit && !remotePermits.find((k: any) => k.value === entityPermit.uuid)) {
      permitsToAdd.push({
        label: entityPermit.name,
        value: entityPermit.uuid
      })
    }
    if (refresh) {
      key.value = 0
    }
    if (key.value === 0) {
      key.value = setCustomKeyVariable()
    }
    permits.value = [...remotePermits, ...permitsToAdd]

    firstTime.value = false

    if (entityPermit) {
      getSearchPermits(entityPermit, entityPermits)
    }
  } catch (err) {
    $showError(err)
    $sentry(err, 'searchPermits')
  }
}

export function getPermitKey () {
  return key.value
}

export function initialPermits () {
  permits.value = []
  key.value = 0
  firstTime.value = true
}